.blip__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 2rem 6rem;
}

.blip__navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blip__navbar-links_logo {
  margin-right: 2rem;
}

.blip__navbar-links_logo img {
  width: 50%;
  height: 50%;
}

.blip__navbar-links_container {
  display: flex;
  flex-direction: row;
  
}

.blip__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.blip__navbar-links_container p,
.blip__navbar-sign p,
.blip__navbar-menu_container p {
  color: #000;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.blip__navbar-links_container a:hover,
.blip__navbar-menu_container a:hover {
  padding-bottom: 5px;
  /*background: url(../../assets/Rectangle\ 17.png) bottom repeat-x;*/
  border-bottom: 2px solid #ff4820;
}

.blip__navbar-sign button,
.blip__navbar-menu_container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.blip__navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.blip__navbar-menu svg {
  cursor: pointer;
}

.blip__navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: white;
  padding: 3rem;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  
  min-width: 210px;
  
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.blip__navbar-menu_container p {
  margin: 1rem 0;
}

.blip__navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 700px) {
  .blip__navbar-links_container {
    display: none;
    
  }

  .blip__navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .blip__navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .blip__navbar {
    padding: 2rem;
  }

  .blip__navbar-sign {
    display: none;
  }

  .blip__navbar-menu_container {
    top: 0px;
  }

  .blip__navbar-menu_container-links-sign {
    display: block;
  }
}
