* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}


.gradient__bg {
  /* ff 3.6+ */
  background:
  radial-gradient(farthest-side at bottom left,
      rgba(157, 98, 155, 0.5),
      transparent 40%),
  radial-gradient(farthest-corner at bottom right,
      rgba(225, 141, 141, 0.3),
      transparent 30%),
  radial-gradient(at 20% 10%,

      rgb(243 136 40 / 24%),
      transparent 20%),
  radial-gradient(farthest-corner at top right,
      rgba(179 54 179 / 33%),
      transparent 30%);

}


.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 8rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

span.underline{
  position: relative;
  white-space: nowrap;
  /* font-weight: 700;
  text-underline-position: under;
  text-decoration: rgb(255, 176, 92) solid underline;
  text-underline-offset: -0.2em;
  text-decoration-thickness:30% */
}
span.underline::before {
  /* Highlight color */
  background-color:   rgb(255, 176, 92) ; 
  white-space: nowrap;
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 0.3em;
  left: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(-1.0deg);
}



.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 4rem 2rem;
  }
}
