@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(200deg, #f38828 00%, #b336b3 80%);
  --gradient-bar: linear-gradient(200deg, #f38828 00%, #b336b3 80%);
  --color-bg: #fffffd;
  --color-footer: rgb(241, 242, 243);
  --color-blog: #042c54;
  --color-text: #6f0071;
  --color-subtext: #ff8a71;
}
